.employeesContentContainer {
    // background-color: blueviolet;
    display: flex;
        flex-direction: column;
        padding-bottom: 25px;


        .contentBlock {
            margin: 25px 35px 5px 35px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            // background-color: bisque;

        }

}