import React, { useRef, useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { authAtom } from '../store/authAtom';
import { getUserProfileData } from '../api';

import ModalWindow from './components/ModalWindow';

import profileIcon from '../assets/profile_picture.png';
import arrowDownIcon from '../assets/arrow_down.png';

import * as style from './style/Layout.module.scss';
import Sidebar from './components/Sidebar';

//
const Layout = () => {
  const [, setAuth] = useAtom(authAtom);
  const [toggleMenu, setToggleMenu] = useState(false);

  const [data, setData] = useState(null);

  useEffect(() => {
    getUserProfileData().then((res) => {
      if (res.ok) {
        setData(res.data);
        // console.log(res.data);
      }
    });
  }, []);

  const menuRef = useRef();
  const imgRef = useRef();
  const nameRef = useRef();
  const btnRef = useRef();
  const arrowRef = useRef();

  window.addEventListener('click', (e) => {
    if (e.target !== menuRef.current && e.target !== arrowRef.current && e.target !== btnRef.current && e.target !== nameRef.current && e.target !== imgRef.current) {
      setToggleMenu(false);
    }
  });

  const navigate = useNavigate();

  const handleLogout = () => {
    setAuth({ isAuthenticated: false });
    localStorage.setItem('isAuthenticated', 'false');
    localStorage.removeItem('authToken');
    // navigate('/login');
  };

  const handleSettings = () => {
    navigate('/settings');
  };

  // const handleLogOut = () => {
  //   logOut().then((d) => {
  //     if (d) {
  //       navigate("/auth/login");
  //     } else {
  //       console.log("error logout");
  //     }
  //   });
  // };

  return (
    <>
      <div className={style.mainContainer}>
        <ModalWindow />
        <Sidebar />
        <div className={style.container}>
          <div className={style.topBar}>
            <div></div>
            <div className={style.rightBlock}>
              <div className={style.profileBlock} ref={btnRef} onClick={() => setToggleMenu(!toggleMenu)}>
                <img src={profileIcon} ref={imgRef} onClick={() => setToggleMenu(!toggleMenu)} />
                <div className={style.pName} ref={nameRef} onClick={() => setToggleMenu(!toggleMenu)}>
                  {data && data[0].value} {data && data[1].value}
                </div>
                <img src={arrowDownIcon} ref={arrowRef} onClick={() => setToggleMenu(!toggleMenu)} />
              </div>
            </div>
          </div>
          <div className={style.mainPageContainer}>
            {toggleMenu && (
              <div ref={menuRef} className={style.profileMenu}>
                <div
                  className={style.menuItem}
                  onClick={() => {
                    setToggleMenu(!toggleMenu);
                    handleSettings();
                  }}
                >
                  <i className='fa-solid fa-gear'></i>
                  <div>Settings</div>
                </div>
                <div
                  className={style.menuItem}
                  onClick={() => {
                    setToggleMenu(!toggleMenu);
                    handleLogout();
                    // handleLogOut();
                  }}
                >
                  <i className='fa-solid fa-right-from-bracket'></i>
                  <div>Logout</div>
                </div>
              </div>
            )}
            <div className={style.content}>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
