import React from 'react';
import loadingIcon from '../../assets/spinner.svg';

import * as style from './Spinner.module.scss';

const Spinner = ({ type }) => {
  return type == 'loading' ? (
    <div className={style.containerLoading}>
      <img src={loadingIcon} />
      Loading...
    </div>
  ) : (
    <div className={style.container}>
      <img src={loadingIcon} />
      Generating...
    </div>
  );
};

export default Spinner;
