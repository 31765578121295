import React from 'react';
import employeeTable from '../../assets/employee-table.svg';

import * as style from './EmployeeTable.module.scss';

const EmployeeTable = () => {
  return (
    <div className={style.employeeTableBlock}>
      <div className={style.titleBar}>
        <div className={style.mainTitle}>Employee Table</div>
        <div></div>
      </div>
      <div className={style.employeeTableContainer}>
        <img src={employeeTable} />
      </div>
    </div>
  );
};

export default EmployeeTable;
