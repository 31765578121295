.dashboardBlock {
    // width: 100%;
    display: flex;
    flex-direction: column;

    .titleBar {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        // background-color: #686868;

        .mainTitle {
            // background-color: aqua;
            display: flex;
            font-size: 24;
            font-weight: 500;
        }

        .dateSelector {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }


    .dashboardCardsContainer {
        display: flex;
        flex-direction: column;

        img {
            margin-top: 15px;
        }
        
        

        .smallCardsContainer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-top: 20px;

            .dashboardCard {
                width: 205px;
                height: 138px;
                border-radius: 10px;
                margin: 0 10px 10px 0;
                background-color: #FFEFE7;
                display: flex;
                flex-direction: column;

                .cardTitle {
                    font-size: 18px;
                    font-weight: 500;
                    margin: 15px 0 0 20px;
                }

                .cardValue {
                    font-size: 36px;
                    font-weight: 600;
                    margin: 0 0 0 20px;
                }

                .cardSubValue {
                    font-size: 16px;
                    font-weight: 300;
                    margin: 0 0 0 20px;
                    color: #FF5151;
                }


            }

            .dashboardCard:nth-child(2) {
                background-color: #E8F0FB;

                .cardSubValue {
                    font-size: 16px;
                    font-weight: 300;
                    margin: 0 0 0 20px;
                    color: #3786F1;
                }
            }

            .dashboardCard:nth-child(3) {
                background-color: #FDEBF9;

                .cardSubValue {
                    font-size: 16px;
                    font-weight: 300;
                    margin: 0 0 0 20px;
                    color: #EE61CF;
                }
            }

        }

        

        .largeCardsContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;

            .dashboardLargeCard {
                width: 310px;
                height: 190px;
                border-radius: 10px;
                border: 1px solid #E0E0E0;
                margin: 0 10px 10px 0;
                // background-color: #FFEFE7;
                display: flex;
                flex-direction: column;

                .cardTitle {
                    font-size: 18px;
                    font-weight: 500;
                    margin: 20px 0 0 20px;
                }
                
                .cardValueContainer {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    .cardValue {
                        font-size: 48px;
                        font-weight: 600;
                        margin: 0 0 10px 20px;
                        // background-color: #FF5151;
                    }

                    .cardSubTitle {
                        font-size: 12px;
                        font-weight: 500;
                        margin: 0 0 0 20px;
                        color: #686868;
                    }

                    .cardValueGraph {
                        // background-color: aquamarine;
                        min-width: 125px;
                        height: 60px;
                        margin-right: 20px;
                    }

                    .cardValueGraphBadge {
                        background-color: #FFEFE7;
                        // min-width: 125px;
                        // height: 30px;
                        margin-right: 20px;
                        font-size: 12px;
                        color: #303030;
                        padding: 5px 15px;
                        border-radius: 5px;
                    }
                }

                

                .cardSubValue {
                    font-size: 12px;
                    font-weight: 300;
                    margin: 0 0 0 20px;
                    color: #686868;
                }

                


            }
        }

        
    }

}

.datePicker {
    // background-color: #3786F1;
    border-radius: 5px;
    width: 130px;
    border: 1px solid #E0E0E0;
    padding: 5px 10px;
    margin-right: 10px;
    color: #686868;
    font-size: 11px;
}