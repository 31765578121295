import React, { useEffect, useState } from 'react';
import { getMainDashboard } from '../api';
import Spinner from './components/Spinner';
import DashboardBlock from './components/DashboardBlock';
import RecentActivity from './components/RecentActivity';
import DashboardChart from '../assets/dashboard-bottom-chart.png';

import * as style from './style/Layout.module.scss';

const DashboardPage = () => {
  return (
    <div className={style.dashboardContentContainer}>
      <div className={style.contentBlock}>
        <DashboardBlock name={'mainDashboard'} type={'small'} />
        <RecentActivity />
      </div>
      <div className={style.contentBlock}>
        <div>
          <img src={DashboardChart} />
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
