.sidebar {
    background-color: #EFF3F5;
    min-width: 270px;
    max-width: 270px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 25px 0;
    height: 100hv;
    min-height: 100%;
    position: fixed; /* Fixed position to keep it in place while scrolling */
    top: 0;
    left: 0;

    a {
        text-decoration: none;
        color: black;
    }



    .createJobBtn {
        width: 220px;
        height: 50px;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #FF5151;
        border-radius: 4px;
        margin: 15px 0 0 10px;
        color: white;

        // img {
        //     margin: 0 20px;
        // }

        i {
            // color: #FF5151;
            color: #fff;
            margin: 0 25px 0 25px;
            font-size: 20px;
        }
    }

    .createJobBtn:hover {
        cursor: pointer;
        background-color: #ff3838;
    }

    .logo {
        margin-top: 10px;
        // background-color: #686868;
    }

    .sep {
        min-width: 200px;
        hr {
            border-top: 0.5px solid whitesmoke;
        }
    }

    .menuBlock {
        // background-color: aqua;
        margin: 40px 0 30px 0;
        width: 240px;
        display: flex;
        flex-direction: column;
        // align-items: flex-start;
        // justify-content: flex-start;
        // background-color: #FF5151;

        .menuTitle {
            font-size: 12px;
            color: #686868;
            padding: 0 30px 5px 30px;
            font-family: "Roboto", sans-serif;
            font-weight: 500;
            // background-color: aquamarine;
        }

        .menuItems {
            ul {
                // background-color: antiquewhite;
                list-style-type: none;
                padding: 0;
                margin: 0;
                width: 100%;

                // img {
                //     width: 20px;
                //     height: 20px;
                //     margin: 0 25px 0 30px;
                //     fill: #FF5151;
                // }

                // a {
                //     text-decoration: none;
                //     // color: black;
                // }

                // a.active {
                //     color: #FF5151;
                // }

                .menuItem {
                    // background-color: aqua;
                    font-family: "Poppins", sans-serif;
                    font-weight: 300;
                    margin: 5px 0;
                    // max-width: 60%;
                    // padding: 0 30px;
                    
                    // padding: 10px 15px;
                    height: 50px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    // justify-content: flex-start;
                    width: 100%;

                    .iconBox {
                        // background-color: #e54848;
                        margin: 0 20px 0 30px;
                        width: 30px;
                        // height: 30px;
                        display: flex;
                        align-items: center;
                        // justify-content: center;
                        

                        i {
                            // color: #FF5151;
                            color: #686868;
                            
                            font-size: 20px;
                        }

                    }


                    

                }


                .menuItem:hover {
                    cursor: pointer;
                    background-color: #e2e8eb;
                    border-radius: 5px;
                }

                
            }
        }
    }
}

.activeLink {
    font-weight: bold;
    color: #ff3838;
}