.mainContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 100px;

        
        .logo {
            padding-bottom: 38px;
        }

        .mainBox {
            max-width: 400px;
            padding: 50px 70;
            border-radius: 8px;
            background: #FFF;
            box-shadow: 0px 0px 102.5px 0px rgba(0, 0, 0, 0.10);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;  
            font-family: Poppins; 

            a {
                color: #007DFA;
                text-decoration: none;
            }

            a:hover {
                color: #0064c8;
                text-decoration: underline;
            }

            
            .title {
                font-size: 28px;
                font-style: normal;
                font-weight: 600;
                color: #101012;
                // background-color: #007DFA;
            }

            form {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding-top: 25px;
                // background-color: aquamarine;
                width: 100%;

                
                .inputArea {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    justify-content: center;
                    // background-color: aqua;
                    margin-bottom: 24px;
                    
                    label {
                        font-size: 16px;
                        font-weight: 400;
                        color: #5A5A5D;
                        padding-bottom: 2px;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                    }

                    input {
                        width: 100%;
                        // background-color: blueviolet;
                        box-sizing: border-box;
                        padding: 12px 16px;
                        border-radius: 8px;
                        border: 1px solid #BEBEBF;
                        color: #5A5A5D;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                    }
                }

                .submitBtn {
                    width: 100%;
                    margin-top: 25px;
                    height: 52px;
                    padding: 16px;
                    background-color: #007DFA;
                    color: #fff;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    border-radius: 8px;
                    border: 0;
                }

                .submitBtn:hover {
                    background-color: #0064c8;
                    cursor: pointer;
                }

                .boxFooter {
                    margin-top: 20px;
                    color: #BEBEBF;


                }
            }
        }
    }



}

h2 {
    padding: 0;
    margin: 0;
}