import React, { useState, useEffect } from 'react';
import { getFunnelData } from '../../api';
import { useAtom } from 'jotai';
import { startDateAtom, endDateAtom, reloadAtom } from '../../store/dateAtoms';
import * as style from './Funnel.module.scss';
import Spinner from './Spinner';

const Funnel = () => {
  const [data, setData] = useState(null);
  const [reload, setReload] = useAtom(reloadAtom);

  const [startDate, setStartDate] = useAtom(startDateAtom);
  const [endDate, setEndDate] = useAtom(endDateAtom);

  useEffect(() => {
    getFunnelData().then((res) => {
      if (res.ok) {
        setData(res.data.data);
        // console.log(res);
      }
    });
  }, []);
  return data ? (
    <div className={style.funnelBlock}>
      <div className={style.titleBar}>
        <div className={style.mainTitle}>Recruitment Funnel</div>
      </div>
      <div className={style.funnelTableContainer}>
        <div className={style.header}>
          <div className={style.col1}>Stage</div>
          <div className={style.col2}>Total</div>
          <div className={style.col3}>Conversion</div>
          <div className={style.col4}>DTS</div>
        </div>
        <div className={style.body}>
          {data.data.map((item) => {
            return (
              <div className={style.row}>
                <div className={style.col1}>{item.title}</div>
                <div className={style.col2}>{item.total}</div>
                <div className={style.col3}>{item.conversion}%</div>
                <div className={style.col4}>{item.dts} d.</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  ) : (
    <Spinner type={'loading'} />
  );
};

export default Funnel;
