import { atom } from 'jotai';

const initialLists = { responsobilities: [], skills: [], qualifications: [], benefits: [] };

const formStructureData = [
  {
    title: 'Job Title',
    name: 'jobTitle',
    type: 'textInput',
  },
  {
    title: 'Description',
    name: 'jobDescription',
    type: 'textArea',
  },
  {
    title: 'Key Responsobilities',
    name: 'responsobilities',
    type: 'advancedTextInput',
  },
  {
    title: 'Skills required',
    name: 'skills',
    type: 'advancedTextInput',
  },
  {
    title: 'Required qualifications',
    name: 'qualifications',
    type: 'advancedTextInput',
  },
  {
    title: 'Benefits',
    name: 'benefits',
    type: 'advancedTextInput',
  },
  {
    title: 'Salary',
    name: 'salary',
    type: 'textInput',
  },
  {
    title: 'Deadline',
    name: 'deadline',
    type: 'textInput',
  },
  {
    title: 'How to Apply',
    name: 'howToApply',
    type: 'textArea',
  },
  {
    title: 'Equal Opportunity Statement',
    name: 'equalOpportunityStatement',
    type: 'textArea',
  },
  {
    title: 'Closing Statement',
    name: 'closingStatement',
    type: 'textArea',
  },
];

export const jobAtom = atom(null);
export const jobListsAtom = atom(initialLists);
export const formsStructureAtom = atom(formStructureData);

// {
//   title: 'Job Title',
//   name: 'jobTitle',
//   type: 'textInput',
// },

// {
//   type: 'preview',
//   jobData: {
//     title: 'Job Title',
//     role: 'Job role',
//   },
// }

// {
//     jobTitle: ['Marketing specialist'],
//     jobDescription: {

//     },
//     keyResponsobilities: {

//     }
// }

// ------------

// const initialData = [
//   {
//     title: 'Job Title',
//     text: 'Marketing Director',
//   },
//   {
//     title: 'Description',
//     text: 'XYZ Tech Solutions is seeking a motivated and detail-oriented Marketing Coordinator to join our growing team. The Marketing Coordinator will play a key role in supporting our marketing efforts and ensuring the successful execution of various campaigns.',
//   },
//   {
//     title: 'Company Overview',
//     text: 'XYZ Tech Solutions is a dynamic and innovative technology company that specializes in developing cutting-edge solutions for the digital era. We are dedicated to creating a collaborative and inclusive work environment where creativity and innovation thrive.',
//   },
//   {
//     title: 'Location',
//     text: 'San Francisco, CA (Remote work options available)',
//   },
//   {
//     title: 'Salary',
//     text: 'Competitive salary based on experience',
//   },
//   {
//     title: 'Benefits',
//     text: ['Health and dental insurance', '401(k) retirement plan', 'Flexible work hours', 'Professional development opportunities'],
//   },
//   {
//     title: 'Key Responsibilities',
//     text: [
//       'Assist in the development and implementation of marketing strategies',
//       'Coordinate and execute digital marketing campaigns across multiple channels',
//       'Create engaging content for social media platforms',
//       'Monitor and analyze the performance of marketing initiatives',
//       'Collaborate with cross-functional teams to ensure alignment with overall business goals',
//     ],
//   },
//   {
//     title: 'Required Qualifications',
//     text: [
//       'Bachelors degree in Marketing, Communications, or a related field',
//       '2+ years of experience in marketing or a relevant role',
//       'Proficient in digital marketing tools and platforms',
//       'Strong written and verbal communication skills',
//       'Ability to manage multiple tasks and deadlines',
//     ],
//   },
//   {
//     title: 'Skills',
//     text: 'Marketing Coordinator',
//   },
//   {
//     title: 'How to Apply',
//     text: 'Marketing Coordinator',
//   },
//   {
//     title: 'Equal Opportunity Statement',
//     text: 'Marketing Coordinator',
//   },
//   {
//     title: 'Closing Statement',
//     text: 'Marketing Coordinator',
//   },
// ];
