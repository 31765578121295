body {
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;
}

.mainContainer {
    display: flex;
    flex-direction: row;
    // background-color: antiquewhite;
    // height: 100vh;
}



.container {
    width: 100%;
    height: 100vh;
    margin-left: 270px;
    // background-color: #EE61CF;

    .topBar {
        // background-color: aquamarine;
        margin-top: -8px;
        height: 95px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #F1F1F1;
        // margin: 0 35px;

        .rightBlock {
            
            // background-color: chocolate;
            display: flex;
            
            margin-right: 15px;
            .profileBlock {
                padding: 10px 15px;
                margin-top: 8px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                .pName {
                    margin: 0 8px 0 12px;
                }
            }

            .profileBlock:hover {
                cursor: pointer;
                    background-color: #f1f5f7;
                    border-radius: 5px;
            }
        }
    }

    .mainPageContainer {
        // background-color: #3786F1;
        display: flex;
        flex-direction: column;
        // justify-items: center;
        align-items: center;

        .content {
            display: flex;
            flex-direction: column;
            // justify-items: center;
            // align-items: center;
            // background-color: #FF5151;
        }
    }

    .dashboardContentContainer {
        // background-color: blueviolet;
        display: flex;
        flex-direction: column;
        padding-bottom: 25px;
        // height: 100vh;
        // background-color: blueviolet;

        .contentBlock {
            margin: 25px 35px 5px 35px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            // background-color: bisque;

            .dashboardBlock {
                // width: 100%;
                display: flex;
                flex-direction: column;

                .dashboardCardsContainer {
                    display: flex;
                    flex-direction: column;
                    
                    

                    .smallCardsContainer {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: 20px;

                        .dashboardCard {
                            width: 205px;
                            height: 138px;
                            border-radius: 10px;
                            margin: 0 10px 10px 0;
                            background-color: #FFEFE7;
                            display: flex;
                            flex-direction: column;
    
                            .cardTitle {
                                font-size: 18px;
                                font-weight: 500;
                                margin: 15px 0 0 20px;
                            }
    
                            .cardValue {
                                font-size: 36px;
                                font-weight: 600;
                                margin: 0 0 0 20px;
                            }
    
                            .cardSubValue {
                                font-size: 16px;
                                font-weight: 300;
                                margin: 0 0 0 20px;
                                color: #FF5151;
                            }
    
    
                        }

                        .dashboardCard:nth-child(2) {
                            background-color: #E8F0FB;

                            .cardSubValue {
                                font-size: 16px;
                                font-weight: 300;
                                margin: 0 0 0 20px;
                                color: #3786F1;
                            }
                        }

                        .dashboardCard:nth-child(3) {
                            background-color: #FDEBF9;

                            .cardSubValue {
                                font-size: 16px;
                                font-weight: 300;
                                margin: 0 0 0 20px;
                                color: #EE61CF;
                            }
                        }

                    }

                    

                    .largeCardsContainer {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 20px;

                        .dashboardLargeCard {
                            width: 310px;
                            height: 190px;
                            border-radius: 10px;
                            border: 1px solid #E0E0E0;
                            margin: 0 10px 10px 0;
                            // background-color: #FFEFE7;
                            display: flex;
                            flex-direction: column;
    
                            .cardTitle {
                                font-size: 18px;
                                font-weight: 500;
                                margin: 20px 0 0 20px;
                            }
                            
                            .cardValueContainer {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                align-items: center;

                                .cardValue {
                                    font-size: 48px;
                                    font-weight: 600;
                                    margin: 0 0 10px 20px;
                                    // background-color: #FF5151;
                                }

                                .cardSubTitle {
                                    font-size: 12px;
                                    font-weight: 500;
                                    margin: 0 0 0 20px;
                                    color: #686868;
                                }

                                .cardValueGraph {
                                    // background-color: aquamarine;
                                    min-width: 125px;
                                    height: 60px;
                                    margin-right: 20px;
                                }

                                .cardValueGraphBadge {
                                    background-color: #FFEFE7;
                                    // min-width: 125px;
                                    // height: 30px;
                                    margin-right: 20px;
                                    font-size: 12px;
                                    color: #303030;
                                    padding: 5px 15px;
                                    border-radius: 5px;
                                }
                            }
    
                            
    
                            .cardSubValue {
                                font-size: 12px;
                                font-weight: 300;
                                margin: 0 0 0 20px;
                                color: #686868;
                            }

                            
    
    
                        }
                    }

                    
                }

            }

            // .titleBar {
            //     width: 100%;
            //     display: flex;
            //     flex-direction: row;
            //     justify-content: space-between;
            //     align-items: center;
            //     // background-color: #686868;

            //     .mainTitle {
            //         // background-color: aqua;
            //         display: flex;
            //         font-size: 24;
            //         font-weight: 500;
            //     }

            //     .dateSelector {
            //         display: flex;
            //         align-items: center;
            //         justify-content: center;
            //     }
            // }

            


        }
    }

    // 

    
}

// .recruitmentContentContainer {
//     background-color: blueviolet;
//     display: flex;
//     flex-direction: column;
//     padding-bottom: 25px;
//     // background-color: blueviolet;
    
//     // . 

//     .contentBlock {
//         margin: 25px 35px 5px 35px;
//         display: flex;
//         flex-direction: row;
//         justify-content: space-between;
//         background-color: bisque;



//         .titleBar {
//             width: 100%;
//             display: flex;
//             flex-direction: row;
//             justify-content: space-between;
//             align-items: center;
//             background-color: #686868;

//             .mainTitle {
//                 // background-color: aqua;
//                 display: flex;
//                 font-size: 24;
//                 font-weight: 500;
//             }

//             .dateSelector {
//                 display: flex;
//                 align-items: center;
//                 justify-content: center;
//             }
//         }

//     // был dashboardBlock

//     }
// }

// .dashboardBlock {
//     // width: 100%;
//     display: flex;
//     flex-direction: column;

//     .dashboardCardsContainer {
//         display: flex;
//         flex-direction: column;
        
        

//         .smallCardsContainer {
//             display: flex;
//             flex-direction: row;
//             justify-content: space-between;
//             align-items: center;
//             margin-top: 20px;

//             .dashboardCard {
//                 width: 205px;
//                 height: 138px;
//                 border-radius: 10px;
//                 margin: 0 10px 10px 0;
//                 background-color: #FFEFE7;
//                 display: flex;
//                 flex-direction: column;

//                 .cardTitle {
//                     font-size: 18px;
//                     font-weight: 500;
//                     margin: 15px 0 0 20px;
//                 }

//                 .cardValue {
//                     font-size: 36px;
//                     font-weight: 600;
//                     margin: 0 0 0 20px;
//                 }

//                 .cardSubValue {
//                     font-size: 16px;
//                     font-weight: 300;
//                     margin: 0 0 0 20px;
//                     color: #FF5151;
//                 }


//             }

//             .dashboardCard:nth-child(2) {
//                 background-color: #E8F0FB;

//                 .cardSubValue {
//                     font-size: 16px;
//                     font-weight: 300;
//                     margin: 0 0 0 20px;
//                     color: #3786F1;
//                 }
//             }

//             .dashboardCard:nth-child(3) {
//                 background-color: #FDEBF9;

//                 .cardSubValue {
//                     font-size: 16px;
//                     font-weight: 300;
//                     margin: 0 0 0 20px;
//                     color: #EE61CF;
//                 }
//             }

//         }

        

//         .largeCardsContainer {
//             display: flex;
//             flex-direction: row;
//             align-items: center;
//             justify-content: space-between;
//             margin-top: 20px;

//             .dashboardLargeCard {
//                 width: 310px;
//                 height: 190px;
//                 border-radius: 10px;
//                 border: 1px solid #E0E0E0;
//                 margin: 0 10px 10px 0;
//                 // background-color: #FFEFE7;
//                 display: flex;
//                 flex-direction: column;

//                 .cardTitle {
//                     font-size: 18px;
//                     font-weight: 500;
//                     margin: 20px 0 0 20px;
//                 }
                
//                 .cardValueContainer {
//                     display: flex;
//                     flex-direction: row;
//                     justify-content: space-between;
//                     align-items: center;

//                     .cardValue {
//                         font-size: 48px;
//                         font-weight: 600;
//                         margin: 0 0 10px 20px;
//                         // background-color: #FF5151;
//                     }

//                     .cardSubTitle {
//                         font-size: 12px;
//                         font-weight: 500;
//                         margin: 0 0 0 20px;
//                         color: #686868;
//                     }

//                     .cardValueGraph {
//                         // background-color: aquamarine;
//                         min-width: 125px;
//                         height: 60px;
//                         margin-right: 20px;
//                     }

//                     .cardValueGraphBadge {
//                         background-color: #FFEFE7;
//                         // min-width: 125px;
//                         // height: 30px;
//                         margin-right: 20px;
//                         font-size: 12px;
//                         color: #303030;
//                         padding: 5px 15px;
//                         border-radius: 5px;
//                     }
//                 }

                

//                 .cardSubValue {
//                     font-size: 12px;
//                     font-weight: 300;
//                     margin: 0 0 0 20px;
//                     color: #686868;
//                 }

                


//             }
//         }

        
//     }

// }

.profileMenu {
    display: flex;
    flex-direction: column;
    // width: 150px;
    align-items: baseline;
    padding: 20px 20px;
    margin-top: 15px;
    border-radius: 10px;
    z-index: 10;
    position: absolute;
    right: 20px;
  
    background-color: white;
    border: 1px solid #e0e0e0;
    box-shadow: 0px 10px 80px 0px rgba(0, 0, 0, 0.04);

    .menuItem {
        // width: 110%;
        width: 100px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 10px 20px 10px 20px;
        // background-color: #3786F1;

        i {
            margin-right: 10px;
        }
    }

    .menuItem:hover {
        background-color: rgba(199, 238, 224, 0.2);
        border-radius: 5px;
        cursor: pointer;
        }

  

  }