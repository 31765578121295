import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { authLoginUser } from '../../api';
import { useAtom } from 'jotai';
import { authAtom } from '../../store/authAtom';

import logo from '../../assets/logo.png';

import * as style from './Auth.module.scss';

const LoginPage = () => {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [, setAuth] = useAtom(authAtom);

  const navigate = useNavigate();
  // const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);
  // const inviteId = searchParams.get('inviteId');

  // console.log(inviteId);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (formData) => {
    // console.log('formData', formData);
    authLoginUser(formData).then((res) => {
      // console.log('==== authLoginUser RESDATA:', res);
      if (res.ok) {
        // console.log(res);
        setAuth({ isAuthenticated: true });
        localStorage.setItem('isAuthenticated', 'true');
        localStorage.setItem('authToken', res.data.token);
        navigate('/');
      }
    });
  };

  return (
    <div className={style.mainContainer}>
      <div className={style.container}>
        <img className={style.logo} src={logo} alt='logo' />
        <div className={style.mainBox}>
          <h2 className={style.title}>Login to your account</h2>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(formData);
            }}
          >
            <div className={style.inputArea}>
              <label htmlFor='email'>Email:</label>
              <input type='email' name='email' value={formData.email} onChange={handleChange} />
            </div>
            <div className={style.inputArea}>
              <label htmlFor='password'>
                Password:
                <div>
                  <a href='#'>Forgot?</a>
                </div>
              </label>
              <input type='password' name='password' value={formData.password} onChange={handleChange} />
            </div>
            <input className={style.submitBtn} type='submit' value='Login Now' />
            <div className={style.boxFooter}>
              Don't have an account? <Link to='../register'>Sign up</Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
