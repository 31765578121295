.employeeTableBlock {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    // background-color: bisque;

    .titleBar {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        // background-color: #686868;

        .mainTitle {
            // background-color: aqua;
            display: flex;
            font-size: 24;
            font-weight: 500;
        }

        .dateSelector {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .employeeTableContainer {
        margin-top: 10px;
    }
}