.recentJobsBlock {
    min-width: 405px;
    max-width: 405px;
    margin: 25px 25px 0 0;
    display: flex;
    flex-direction: column;
    // align-items: center;
    border: 1px solid #3786F1;
    border-radius: 10px;
    // justify-content: center;
    // background-color: aqua;

    .header {
        width: 100%;
        padding: 12px 0;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background-color: #3786F1;
        border: 1px solid #3786F1;
        color: white;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 3px;

        .title {
            margin-left: 30px;
        }
    }

    .item {
        display: flex;
        flex-direction: column;
        // align-items: center;
        // background-color: aqua;
        padding: 15px 30px;
        margin-bottom: 3px;

        a {
            text-decoration: none;
            color: black;
        }

        .title {
            font-size: 18px;
            font-weight: 400;
        }

        .subTitle {
            font-size: 14px;
            font-weight: 200;
        }

        .dateTime {
            font-size: 10px;
            font-weight: 200;
        }
    }

    .item:hover {
        cursor: pointer;
        background-color: #E8F0FB;
    }
}
