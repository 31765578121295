import React, { useState, useEffect } from 'react';
import { getRecruitmentDashboard } from '../api';
import Spinner from './components/Spinner';
import DashboardBlock from './components/DashboardBlock';
import Funnel from './components/Funnel';
import RecentJobsBlock from './components/RecentJobsBlock';

// import * as style from './style/Layout.module.scss';
import * as style from './style/RecruitmentPage.module.scss';

const RecruitmentPage = () => {
  return (
    <div className={style.recruitmentContentContainer}>
      <div className={style.contentBlock}>
        <DashboardBlock name={'recruitmentDashboard'} type={'small'} />
        <Funnel />
      </div>
      <RecentJobsBlock />
    </div>
  );
};

export default RecruitmentPage;
