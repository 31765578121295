.windowOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
    // background-color: aqua;
  }


  .modalWindow {
    background: rgb(255, 255, 255);
    padding: 20px;
    border-radius: 5px;
    position: relative;
    max-height: 80%; 
  overflow-y: auto;

      .mainContainer {
          width: 500px;
          // height: 300px;
          display: flex;
          flex-direction: column;
          // align-items: center;
          // justify-content: center;

          .title {
            font-size: 24px;
          }

          .contentBox {
            margin: 10px 15px;
            display: flex;
            flex-direction: column;
            // background-color: #ff3838;

            .subTitle {
              font-size: 18px;
              font-weight: 500;
            }

            .info {
              margin: 10px 0;
              font-size: 14px;
            }

            .formContent {
              // margin: 10px 10px;

              textarea {
                width: 100%;
                // background-color: blueviolet;
                box-sizing: border-box;
                padding: 12px 16px;
                border-radius: 5px;
                border: 1px solid #BEBEBF;
                color: #5A5A5D;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                resize: vertical;
                margin: 15px 0;
              }
  
              .inputBlock {
                display: flex;
                flex-direction: column;
                margin: 0 15px 20px 15px;

                
                
                label {
                  font-size: 16px;
                  font-weight: 300;
                  color: #000;
                  padding-bottom: 2px;
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                  width: 100%;
              }
  
              input {
                  width: 100%;
                  // background-color: blueviolet;
                  box-sizing: border-box;
                  padding: 12px 16px;
                  border-radius: 8px;
                  border: 1px solid #BEBEBF;
                  color: #5A5A5D;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
              }
  
              }
            }

          }



          

          .footer {
            display: flex;
            align-items: center;
            justify-content: center;

            .createBtn {
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 4px;
              background-color: #FF5151;
              color: white;
              padding: 10px 20px;
            }

            .createBtn:hover {
              cursor: pointer;
              background-color: #ff3838;
            }

          }
      }

      .jobPreviewContainer {
        width: 730px;
          // height: 300px;
          display: flex;
          flex-direction: column;
          // align-items: center;
          // justify-content: center;

          .title {
            font-size: 24px;
            margin-left: 10px;
          }

          .paragraph {
            display: flex;
            flex-direction: column;
            margin: 15px 35px 10px 35px;

            .paragraphTitle {
            font-size: 17px;
            font-weight: 600;
            }

            .text {
              font-size: 14px;
            }

            ul {
              margin: 0;
              li {
                font-size: 14px;
              }
            }
          }

          
      }

      .closeWindowBtn {
        // position: fixed;
        position: absolute;
        top: 18px;
        right: 15px;
        padding: 10px;
        // width: 25px;
        // height: 30px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        z-index: 10;
        // background-color: aqua;

        img {
          width: 15px;
        }
    
      }
      

  }

