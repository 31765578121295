import React, { useEffect, useState } from 'react';
import { jobBlockGenerate, jobListBlockGenerate, jobBlockParaphrase, listItemParaphrase, createJobPost } from '../api/index';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { jobListsAtom, formsStructureAtom } from '../store/jobAtoms';
import Spinner from './components/Spinner';

import * as style from './style/JobCreatePage.module.scss';

const JobLoading = () => {
  return <div className={style.contentBlock}>Creating Job Post...</div>;
};

const ListItem = ({ item, index, listName, jobTitle }) => {
  const [hover, setHover] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState('');
  const [lists, setLists] = useAtom(jobListsAtom);

  useEffect(() => {
    // setTimeout(() => {
    //   setValue(item);
    //   console.log('Delayed for 1 second.');
    // }, 3000);
    setValue(item);
  }, []);

  const handleSave = () => {
    console.log('--------------', value);
    const newList = [];
    lists[listName].forEach((item, i) => {
      if (i == index) {
        newList.push(value);
      } else {
        newList.push(item);
      }
    });

    // console.log(newList);
    setLists({ ...lists, [listName]: [...newList] });
    setIsFocused(false);
    // setLists({ ...lists, [listName]: [...список без старого , новый value] });
  };

  const handleRemove = () => {
    const newList = [];
    lists[listName].forEach((item, i) => {
      if (i != index) {
        newList.push(item);
      }
    });

    // console.log(newList);
    setLists({ ...lists, [listName]: [...newList] });
    setIsFocused(false);
  };

  const handleParaphrase = () => {
    const bodyData = {
      item: value,
      jobTitle: 'Sales rep',
      type: 'Key Responsobilities',
    };
    listItemParaphrase(bodyData).then((data) => {
      // console.log(data);
      setValue(data.data[0]);
    });
  };

  // console.log(item);

  return isFocused ? (
    <div className={style.listItemBlockFocused} onclic>
      <div>
        {/* <input type='text' name={structure.name} onChange={handleChange} value={formData[structure.name]} /> */}
        {/* <input type='text' /> */}
        <textarea autoFocus className={style.textEditArea} rows='2' cols='60' value={value} onChange={(e) => setValue(e.target.value)} />
      </div>
      <div className={style.buttonsContainer}>
        <div className={style.paraphraseBtn} onClick={handleParaphrase}>
          Paraphrase
        </div>
        <div className={style.right}>
          <div className={style.removeBtn} onClick={handleRemove}>
            Remove
          </div>
          <div className={style.saveBtn} onClick={handleSave}>
            Save
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className={style.listItemBlock} key={item} onClick={() => setIsFocused(true)} onMouseOver={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <div className={style.listItem}>
        <i className='fa-solid fa-caret-right'></i>
        {item}
      </div>
      <div className={style.itemEdit}>{hover && <i className='fa-solid fa-pen'></i>}</div>
    </div>
  );
};

const TextInputBlock = ({ formData, structure, handleChange }) => {
  // console.log('');
  return (
    <div className={style.inputBlock}>
      <label htmlFor={structure.name}>{structure.title}</label>
      <div className={style.inputArea}>
        <input type='text' name={structure.name} onChange={handleChange} value={formData[structure.name]} />
      </div>
    </div>
  );
};

const TextAreaBlock = ({ formData, structure, handleChange, generate }) => {
  const [loading, setLoading] = useState(false);

  const handleGenerate = () => {
    setLoading(true);
    jobBlockGenerate({ jobTitle: formData['jobTitle'], type: structure.title }).then((data) => {
      setLoading(false);
      if (data.ok) {
        // console.log(data);
        generate(data.data[0], structure.name);
      }
    });
  };

  const handleParaphrase = (e) => {
    setLoading(true);
    jobBlockParaphrase({ jobTitle: formData['jobTitle'], type: structure.title, data: e.target.value }).then((data) => {
      setLoading(false);
      if (data.ok) {
        // console.log(data);
        generate(data.data[0], structure.name);
      }
    });
  };

  return (
    <div className={style.inputBlock}>
      <label htmlFor={structure.name}>{structure.title}</label>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <textarea rows='7' cols='60' name={structure.name} onChange={handleChange} value={formData[structure.name]}></textarea>
          <div className={style.buttonsArea}>
            <div className={style.underlineBtn} onClick={handleGenerate}>
              <div className={style.icon}>⚡</div>Generate
            </div>
            <div className={style.mainBtn} onClick={(e) => handleParaphrase(e)}>
              Paraphrase
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const AdvancedTextInputBlock = ({ formData, handleChange, structure }) => {
  const [loading, setLoading] = useState(false);
  const [lists, setLists] = useAtom(jobListsAtom);

  const handleListAdd = (name) => {
    if (lists) {
      setLists({ ...lists, [name]: [...lists[name], formData[name]] });
    } else {
      setLists({ [name]: [formData[name]] });
    }
    formData[name] = '';
  };

  const handleGenerate = (name) => {
    setLoading(true);
    jobListBlockGenerate({ jobTitle: formData['jobTitle'], type: structure.title }).then((data) => {
      setLoading(false);
      if (data.ok) {
        console.log(data.data);
        setLists({ ...lists, [name]: [...lists[name], ...data.data] });
        // писать в атом
        // походу тут settimer
        // generate(data.data[0], structure.name);
      }
    });
  };

  // console.log('structure.name', structure.name);
  return (
    <div className={style.inputBlock}>
      <label htmlFor={structure.name}>{structure.title}</label>
      <div className={style.inputArea}>
        <input type='text' name={structure.name} onChange={handleChange} value={formData[structure.name]} />
        <div className={style.addBtn} onClick={() => handleListAdd(structure.name)}>
          Add
        </div>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <div className={style.listArea}>
          {/* <ul>{lists && lists[structure.name].map((item) => <li key={item}>{item}</li>)}</ul> */}
          <div className={style.listContainer}>
            {lists && lists[structure.name].map((item, index) => <ListItem key={index} item={item} index={index} listName={structure.name} />)}
          </div>
          <div className={style.buttonsArea}>
            <div className={style.underlineBtn} onClick={() => handleGenerate(structure.name)}>
              {/* <i className='fa-regular fa-lightbulb'></i> */}
              <div className={style.icon}>💡</div>
              Suggest
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const JobCreatePage = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ jobTitle: '' });
  const [formStructureData, setFormStructureData] = useAtom(formsStructureAtom);
  const [lists, setLists] = useAtom(jobListsAtom);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    // как-то сложить массив
    // setJobData();
  };

  const generate = (data, name) => {
    setFormData({ ...formData, [name]: data });
    // console.log('--------', data);
    // console.log('--------', name);
  };

  const navigate = useNavigate();

  const handleCreateJob = async () => {
    setLoading(true);

    const jobDataList = [];

    formStructureData.forEach((item) => {
      if (item.type != 'advancedTextInput') {
        jobDataList.push({
          name: item.name,
          title: item.title,
          text: formData[item.name] ? formData[item.name] : '-',
        });
      } else {
        jobDataList.push({
          name: item.name,
          title: item.title,
          text: lists[item.name] ? lists[item.name] : '-',
        });
      }
    });

    // console.log('jobDataList', jobDataList);

    const r = (Math.random() + 1).toString(36).substring(7);

    const newJobPost = {
      data: jobDataList,
    };
    // var retrievedJobPosts = localStorage.getItem('jobPosts');
    // console.log('retrievedJobPosts', JSON.parse(retrievedJobPosts));

    // if (retrievedJobPosts) {
    //   localStorage.setItem('jobPosts', JSON.stringify(newJobPosts.push()));
    // } else {
    // }

    // localStorage.setItem('jobPost', JSON.stringify(newJobPost));

    createJobPost(newJobPost).then((res) => {
      if (res.ok) {
        // console.log(res.data.job_post_id);
        const initialLists = { responsobilities: [], skills: [], qualifications: [], benefits: [] };
        setLists(initialLists);
        navigate(`/job/${res.data.job_post_id}`);
      }
    });
    // setJobData(null);
    // jobGenerate(formData).then((data) => {
    //   setLoading(false);
    //   if (data.ok) {
    //     // рудирект на /:job-id (jobPage)
    //     // setJob(data);
    //     // SetWindowComponent('job-post-generated-preview');
    //     navigate(`./${data.jobId}`);
    //   }
    //   // else если ошибка, то в лоадинг или попап....
    // });
  };

  const handleListChange = (list) => {
    // setFormListData({ ...formListData, [list.name]: list.text });
    // // setFormListData(formListData[list]});
    // console.log('list', list);
    // console.log('formListData', formListData);
    // // setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return loading ? (
    <JobLoading />
  ) : (
    <div className={style.jobContentContainer}>
      <div className={style.contentBlock}>
        <div className={style.mainContainer}>
          <div className={style.title}>Create New Job Post</div>
          <div className={style.formContent}>
            {formStructureData.map((item, i) => {
              if (item.type == 'textInput') {
                return <TextInputBlock key={i} formData={formData} structure={item} handleChange={handleChange} />;
              }
              if (item.type == 'advancedTextInput') {
                return <AdvancedTextInputBlock key={i} formData={formData} structure={item} handleChange={handleChange} />;
              }

              if (item.type == 'textArea') {
                return <TextAreaBlock key={i} formData={formData} structure={item} handleChange={handleChange} generate={generate} />;
              }
            })}
          </div>
          <div className={style.createBtn} onClick={() => handleCreateJob()}>
            Create Job Post
          </div>
        </div>
      </div>
    </div>
  );
  // return <div className={style.jobContentContainer}>{loading ? <JobLoading /> : <JobCreateBlock />}</div>;
};

export default JobCreatePage;
