import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAtom } from 'jotai';
import { windowVisibilityAtom, windowContentComponent } from '../../store/windowAtoms';

import logo from '../../assets/logo-app.png';

import dashboardIcon from '../../assets/dash-icon-gray.svg';
import * as style from './Sidebar.module.scss';

const MenuBlock = ({ openWindow }) => {
  return (
    <div className={style.menuBlock}>
      <div className={style.menuTitle}>MAIN MENU</div>
      <div className={style.menuItems}>
        <ul>
          <li>
            <NavLink to='/'>
              <div className={style.menuItem}>
                <div className={style.iconBox}>
                  <i className='fa-solid fa-chart-simple'></i>
                </div>
                Dashboard
              </div>
            </NavLink>
          </li>
          <li>
            <NavLink to='/recruitment'>
              <div className={style.menuItem}>
                <div className={style.iconBox}>
                  <i className='fa-solid fa-bullhorn'></i>
                </div>
                Recruitment
              </div>
            </NavLink>
          </li>
          <li>
            <NavLink to='/employees'>
              <div className={style.menuItem}>
                <div className={style.iconBox}>
                  <i className='fa-solid fa-user-check'></i>
                </div>
                Employees
              </div>
            </NavLink>
          </li>
          {/* <li>
            <NavLink
              to='/employees'
              //   style={({ isActive }) => ({
              //     color: isActive ? 'greenyellow' : 'white',
              //   })}
            >
              <div className={style.menuItem}>
                <div className={style.iconBox}>
                  <i className='fa-solid fa-user-check'></i>
                  Employees
                </div>
              </div>
            </NavLink>
          </li> */}
        </ul>
        <NavLink to='/job'>
          <div className={style.createJobBtn}>
            <i className='fa-solid fa-pen-clip'></i>
            Create Job Post
          </div>
        </NavLink>
      </div>
    </div>
  );
};

const Sidebar = () => {
  const [isVisible, setVisibility] = useAtom(windowVisibilityAtom);
  const [windowComponent, SetWindowComponent] = useAtom(windowContentComponent);

  const handleOpenModalWindow = () => {
    SetWindowComponent('support-window-main');
    setVisibility(true);
  };

  return (
    <div className={style.sidebar}>
      <div className={style.logo}>
        <img src={logo} />
      </div>
      <MenuBlock openWindow={handleOpenModalWindow} />
      <div>
        <div className={style.sep}>
          <hr></hr>
        </div>
      </div>
      <div className={style.menuBlock}>
        <div className={style.menuTitle}>OTHER</div>
        <div className={style.menuItems}>
          <ul>
            <li>
              <div className={`${style.menuItem}`} onClick={handleOpenModalWindow}>
                <div className={style.iconBox}>
                  <i className='fa-solid fa-headset'></i>
                </div>
                Support
              </div>
            </li>
            <li>
              <NavLink className={({ isActive }) => (isActive ? 'bg-green-500 font-bold' : 'bg-red-500 font-thin')} to='/settings'>
                <div className={style.menuItem}>
                  <div className={style.iconBox}>
                    <i className='fa-solid fa-gear'></i>
                  </div>
                  Settings
                </div>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
