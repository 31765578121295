import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getJobPostList } from '../../api';

import * as style from './RecentActivity.module.scss';

const ActivityItem = ({ data }) => {
  return (
    <div className={style.acivityItem}>
      <Link to={`/job/${data.id}`}>
        <div className={style.date}>{data.created_at}</div>
        <div className={style.title}>{data.job_title}</div>
        <div className={style.message}>{data.description.substring(0, 134)}...</div>
      </Link>
    </div>
  );
};

const RecentActivity = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    setLoading(true);
    getJobPostList().then((res) => {
      if (res.ok) {
        setLoading(false);
        // console.log(res);
        setData(res.data.data);
      }
    });
  }, []);

  return !loading ? (
    data ? (
      <div className={style.activityBlock}>
        <div className={style.titleBar}>Recently Created Jobs</div>
        {data.length ? (
          <>
            <div className={style.acivityItems}>{data.map((item, i) => (i <= 1 ? <ActivityItem data={item} /> : null))}</div>
            <div className={style.footer}>
              {/* <div className={style.info}>Today you made 12 Job Posts</div> */}
              <Link to='/recruitment'>
                <div className={style.seeActivityBtn}>See All Activity</div>
              </Link>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    ) : (
      <>Loading...</>
    )
  ) : (
    <>Loading...</>
  );
};

export default RecentActivity;
