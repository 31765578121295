.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // background-color: aqua;
    margin: 60px;

    img {
        width: 70px;
        margin-bottom: 5px;
    }
    // position: absolute;
    // top: 0;
    // left: 0;
    // width: 100%;
    // height: 100%;
}

.containerLoading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // background-color: aqua;
    margin: 30px;

    img {
        width: 70px;
        margin-bottom: 5px;
    }
}