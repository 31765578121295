import { createBrowserRouter } from 'react-router-dom';

import Base from '../view/Base';
import Layout from '../view/Layout';
import DashboardPage from '../view/DashboardPage';
import RecruitmentPage from '../view/RecruitmentPage';
import EmployeesPage from '../view/EmployeesPage';
import JobCreatePage from '../view/JobCreatePage';
import JobPage from '../view/JobPage';
import SettingsPage from '../view/SettingsPage';

import LoginPage from '../view/auth/LoginPage';
import RegisterPage from '../view/auth/RegisterPage';

const router = createBrowserRouter([
  // {
  //   path: '/',
  //   element: <Layout />,
  //   children: [
  //     { path: '/', element: <DashboardPage /> },
  //     { path: '/recruitment', element: <RecruitmentPage /> },
  //     { path: '/employees', element: <>employees</> },
  //   ],
  // },
  {
    path: '/',
    element: <Base />,
    children: [
      {
        path: '/',
        element: <Layout />,
        children: [
          { path: '/', element: <DashboardPage /> },
          { path: '/recruitment', element: <RecruitmentPage /> },
          { path: '/employees', element: <EmployeesPage /> },
          { path: '/job', element: <JobCreatePage /> },
          { path: '/job/:jobId', element: <JobPage /> },
          { path: '/settings', element: <SettingsPage /> },
        ],
      },
    ],
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/register',
    element: <RegisterPage />,
  },
  // {
  //   path: '/',
  //   element: <Base />,
  //   children: [
  //     {
  //       path: '/',
  //       element: <p>DASHBOARD</p>,
  //     },
  //     {
  //       path: 'dashboard',
  //       element: <p>DASHBOARD</p>,
  //     },
  //     {
  //       path: 'recruitment',
  //       element: <p>Recruitment</p>,
  //     },
  //     {
  //       path: 'employees',
  //       element: <p>Employees</p>,
  //     },
  //     {
  //       path: 'login',
  //       element: <LoginPage />,
  //     },
  //     {
  //       path: 'register',
  //       element: <RegisterPage />,
  //     },
  //   ],
  // },
  // ------------
  // {
  //   path: '/auth',
  //   element: (
  //     <AuthRoutes>
  //       <AuthLayout />
  //     </AuthRoutes>
  //   ),
  //   children: [
  //     {
  //       path: 'login',
  //       element: <LoginPage />,
  //     },
  //     {
  //       path: 'register',
  //       element: <RegisterPage />,
  //     },
  //   ],
  // },
]);

export default router;

// dash
// hiring
// performance
