import React, { useState, useEffect } from 'react';
import { getJobPostList } from '../../api';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';

import * as style from './RecentJobsBlock.module.scss';

const Item = ({ data }) => {
  // console.log(data);
  return (
    <div className={style.item}>
      <Link to={`/job/${data.id}`}>
        <div className={style.dateTime}>{data.created_at}</div>
        <div className={style.title}>{data.job_title}</div>
        <div className={style.subTitle}>{data.description.substring(0, 80)}...</div>
      </Link>
    </div>
  );
};

const RecentJobsBlock = () => {
  const [jobPosts, setJobPosts] = useState(null);

  useEffect(() => {
    getJobPostList().then((res) => {
      if (res.ok) {
        setJobPosts(res.data.data);
      }
    });
  }, []);
  return (
    <div className={style.recentJobsBlock}>
      <div className={style.header}>
        <div className={style.title}>Recently Created Jobs</div>
      </div>
      {jobPosts ? jobPosts.map((item) => <Item data={item} />) : <Spinner type={'loading'} />}
    </div>
  );
};

export default RecentJobsBlock;
