import React, { useState } from 'react';
import { useAtom } from 'jotai';
import { windowVisibilityAtom, windowContentComponent } from '../../store/windowAtoms';
import { jobAtom } from '../../store/jobAtoms';
import { Spinner } from '../../helpers/Spinner';

import closeIcon from '../../assets/close-btn.svg';

import * as style from './ModalWindow.module.scss';

const CreateJobPost = () => {
  const [windowComponent, SetWindowComponent] = useAtom(windowContentComponent);
  const [job, setJob] = useAtom(jobAtom);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({ jobTitle: '', jobDescription: '', qualifications: '' });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleCreateJob = async () => {
    setLoading(true);
    // jobGenerate(formData).then((data) => {
    //   setLoading(false);
    //   if (data) {
    //     setJob(data);
    //     SetWindowComponent('job-post-generated-preview');
    //   }
    // });
    //
  };

  return loading ? (
    <Spinner />
  ) : (
    <div className={style.mainContainer}>
      <div className={style.title}>Create Job Post</div>
      <div className={style.formContent}>
        <div className={style.inputBlock}>
          <label htmlFor='jobTitle'>Job Title:</label>
          <input type='text' name='jobTitle' value={formData.jobTitle} onChange={handleChange} />
        </div>
        <div className={style.inputBlock}>
          <label htmlFor='jobDescription'>Job Description:</label>
          <input type='text' name='jobDescription' value={formData.jobDescription} onChange={handleChange} />
        </div>
        <div className={style.inputBlock}>
          <label htmlFor='qualifications'>Qualifications:</label>
          <input type='text' name='qualifications' value={formData.qualifications} onChange={handleChange} />
        </div>
      </div>
      <div className={style.footer}>
        <div className={style.createBtn} onClick={() => handleCreateJob()}>
          Generate
        </div>
      </div>
    </div>
  );
};

const JobPostPreview = () => {
  const [job, setJob] = useAtom(jobAtom);
  return job ? (
    <div className={style.jobPreviewContainer}>
      <div className={style.title}>Preview</div>
      {job.jobData.map((item) => (
        <div className={style.paragraph}>
          <div className={style.paragraphTitle}>{item.title}</div>
          {typeof item.text === 'string' ? (
            <div className={style.text}>{item.text}</div>
          ) : (
            <ul>
              {item.text.map((subItem) => (
                <li>{subItem}</li>
              ))}
            </ul>
          )}
        </div>
      ))}

      <div className={style.footer}>
        <div className={style.createBtn}>Regenerate</div>
        {/* <div className={style.createBtn}>Copy</div> */}
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

// const NewJobPostForm = () => {
//   const [formData, setFormData] = useState({ jobTitle: '', jobDescription: '', qualifications: '' });

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   // console.log(formData);

//   return (
//     <div className={style.formContent}>
//       <div className={style.inputBlock}>
//         <label htmlFor='jobTitle'>Job Title:</label>
//         <input type='text' name='jobTitle' value={formData.jobTitle} onChange={handleChange} />
//       </div>
//       <div className={style.inputBlock}>
//         <label htmlFor='jobDescription'>Job Description:</label>
//         <input type='text' name='jobDescription' value={formData.jobDescription} onChange={handleChange} />
//       </div>
//       <div className={style.inputBlock}>
//         <label htmlFor='qualifications'>Qualifications:</label>
//         <input type='text' name='qualifications' value={formData.qualifications} onChange={handleChange} />
//       </div>
//     </div>
//   );
// };

const SupportWindowMain = ({ handleSend }) => {
  const [loading, setLoading] = useState(false);

  return loading ? (
    <Spinner />
  ) : (
    <div className={style.mainContainer}>
      <div className={style.title}>Support Center</div>
      <div className={style.contentBox}>
        {/* <div className={style.subTitle}>How can we assist you today?</div> */}
        <div className={style.info}>
          We're here to help! If you have any questions, concerns, or feedback, please don't hesitate to reach out to us using the form below. Our team will get back to
          you as soon as possible.
        </div>
        <div className={style.formContent}>
          <textarea rows='4' cols='60' />
        </div>
      </div>
      <div className={style.footer}>
        <div className={style.createBtn} onClick={handleSend}>
          Send
        </div>
      </div>
    </div>
  );
};

const ModalWindow = () => {
  const [isVisible, setVisibility] = useAtom(windowVisibilityAtom);
  const [windowComponent, SetWindowComponent] = useAtom(windowContentComponent);

  const handleSend = () => {
    setVisibility(false);
  };

  return (
    isVisible && (
      <div className={style.windowOverlay}>
        <div className={style.modalWindow}>
          <div className={style.closeWindowBtn} onClick={() => setVisibility(false)}>
            <img src={closeIcon} />
          </div>
          {windowComponent === 'support-window-main' && <SupportWindowMain handleSend={handleSend} />}
          {/* {windowComponent === 'job-post-create' && <CreateJobPost />}
          {windowComponent === 'job-post-generated-preview' && <JobPostPreview />} */}
        </div>
      </div>
    )
  );
};

export default ModalWindow;
