import React, { useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAtom } from 'jotai';
import { authAtom } from '../store/authAtom';

const Base = () => {
  const [auth] = useAtom(authAtom);
  // console.log(auth);

  return auth.isAuthenticated ? <Outlet /> : <Navigate to='/login' replace={true} state={{ from: window.location.pathname }} />;
};

export default Base;
