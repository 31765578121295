.jobContentContainer {
    // background-color: blueviolet;
    display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 25px;


        .contentBlock {
            margin: 25px 35px 5px 35px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            // background-color: bisque;

            .mainContainer {
                width: 600px;
                // height: 300px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                // background-color: #5A5A5D;
      
                .title {
                  font-size: 24px;
                }

                .createBtn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 4px;
                    background-color: #FF5151;
                    color: white;
                    padding: 10px 20px;
                  }
      
                  .createBtn:hover {
                    cursor: pointer;
                    background-color: #ff3838;
                  }
      
                .formContent {
                  margin: 10px 0;
                  padding: 30px 0;
                  width: 90%;
                //   border-radius: 10px;
                  //   padding: 50px 40px;
                //   background-color: rgb(247, 247, 247);

      
                  .inputBlock {
                    display: flex;
                    flex-direction: column;
                    margin: 0 15px 20px 15px;
                    
                    label {
                      font-size: 16px;
                      font-weight: 300;
                      color: #000;
                      padding-bottom: 2px;
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      align-items: center;
                      width: 100%;
                    }
      
                  input {
                      width: 100%;
                      // background-color: blueviolet;
                      box-sizing: border-box;
                      padding: 12px 16px;
                      border-radius: 8px;
                      border: 1px solid #BEBEBF;
                      color: #5A5A5D;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                    }
      
                  }
                }
            }

            .jobPreviewContainer {
                // width: 730px;
                  // height: 300px;
                  display: flex;
                  flex-direction: column;
                  // align-items: center;
                  // justify-content: center;
        
                  .title {
                    font-size: 24px;
                    // margin-left: 10px;
                  }
        
                  .paragraph {
                    display: flex;
                    flex-direction: column;
                    margin: 0 35px 30px 35px;
                    // background-color: #ff3838;
        
                    .paragraphTitle {
                    font-size: 20px;
                    font-weight: 600;
                    margin-bottom: 5px;
                    }
        
                    .text {
                      font-size: 14px;
                    }
        
                    ul {
                      margin: 0;
                      li {
                        font-size: 14px;
                      }
                    }
                  }

                  .btnArea {
                    width: 250px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    // background-color: #ff3838;
                    margin-bottom: 50px;

                    .btnEdit {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      border-radius: 4px;
                      background-color: #FF5151;
                      color: white;
                      padding: 10px 40px;
                      cursor: pointer;
                    }

                    .btnCopy {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      border-radius: 4px;
                      // background-color: #FF5151;
                      // color: white;
                      text-decoration: underline;
                      padding: 10px 20px;
                      cursor: pointer;
                    }
                  }
        
                  
              }
        }

}