import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { authRegisterUser } from '../../api';
import { useAtom } from 'jotai';
import { authAtom } from '../../store/authAtom';
import toast, { Toaster } from 'react-hot-toast';

import logo from '../../assets/logo.png';

import * as style from './Auth.module.scss';
const RegisterPage = () => {
  const [formData, setFormData] = useState({ email: '', password: '', confirmPassword: '' });
  const [, setAuth] = useAtom(authAtom);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const navigate = useNavigate();

  const handleSubmit = (formData) => {
    // console.log('formData', formData);
    const urlParams = new URLSearchParams(window.location.search);
    const invite = urlParams.get('invite');

    if (invite) {
      formData['token'] = invite;
      authRegisterUser(formData).then((res) => {
        if (res.ok) {
          // console.log(res);
          setAuth({ isAuthenticated: true });
          localStorage.setItem('isAuthenticated', 'true');
          localStorage.setItem('authToken', res.data.token);
          navigate('/');
        }
      });
    } else {
      toast.error('No invite token found!');
    }
  };

  return (
    <div className={style.mainContainer}>
      <div className={style.container}>
        <img className={style.logo} src={logo} alt='logo' />
        <div className={style.mainBox}>
          <h2 className={style.title}>Register new account</h2>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(formData);
            }}
          >
            <div className={style.inputArea}>
              <label htmlFor='email'>Email:</label>
              <input type='email' name='email' value={formData.email} onChange={handleChange} />
            </div>
            <div className={style.inputArea}>
              <label htmlFor='password'>Password:</label>
              <input type='password' name='password' value={formData.password} onChange={handleChange} />
            </div>
            <div className={style.inputArea}>
              <label htmlFor='confirmPassword'>Repeat Password:</label>
              <input type='password' name='confirmPassword' value={formData.confirmPassword} onChange={handleChange} />
            </div>
            <input className={style.submitBtn} type='submit' value='Register' />
            <div className={style.boxFooter}>
              Already have an account? <Link to='../login'>Sign In</Link>
            </div>
          </form>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default RegisterPage;
