.activityBlock {
    background-color: #161E54;
    max-width: 425px;
    min-width: 425px;
    margin-left: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;

    // justify-content: space-between;


    .titleBar {
        font-size: 18px;
        font-weight: 500;
        color: #FFFFFF;
        background-color: #1B204A;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        // height: 45px;
        padding: 15px 25px;
    }

    .acivityItems {
        // background-color: aqua;
        margin: 5px 10px 15px 10px;

        .acivityItem {
            // background-color: bisque;
            display: flex;
            flex-direction: column;
            padding: 10px 15px;

            .date {
                font-size: 10px;
                font-weight: 100;
                color: #FFFFFF;
            }

            .title {
                font-size: 18px;
                font-weight: 400;
                color: #FFFFFF;
                margin-top: 4px;
            }

            .message {
                font-size: 13px;
                font-weight: 100;
                color: #FFFFFF;
                margin: 0 0 5px 0;
            }

        }

        .acivityItem:hover {
            cursor: pointer;
            background-color: #2f3882;
            border-radius: 5px;
        }
    }

    .footer {
        margin: 30px 20px 30px 20px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        
            .info {
                color: white;
                font-size: 14px;
                font-weight: 200;
            }
    
            .seeActivityBtn {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 140px;
                height: 35px;
                color: white;
                font-size: 14px;
                background-color: #FF5151;
                border-radius: 4px;
            }
    
            .seeActivityBtn:hover {
                background-color: #ff6262;
                cursor: pointer;
            }    

    }
}

a {
    text-decoration: none;
}