import React from 'react';
import EmployeeTable from './components/EmployeeTable';
import DashboardBlock from './components/DashboardBlock';

import * as style from './style/EmployeesPage.module.scss';

const initialEmployeeData = {
  title: 'Employee Dashboard',
  dashboards: [
    {
      title: 'Motivation',
      smallCardData: [
        {
          title: 'Candidate Fit',
          value: '7 of 10',
          subTitle: 'Interviews',
          subValue: 976,
        },
        {
          title: 'Cost per hire',
          value: '$78',
          subTitle: '',
          subValue: 'From $45 to $89',
        },
      ],
    },
    {
      title: 'Information',
      smallCardData: [
        {
          title: 'Candidate Fit',
          value: '7 of 10',
          subTitle: 'Interviews',
          subValue: 976,
        },
        {
          title: 'Cost per hire',
          value: '$78',
          subTitle: '',
          subValue: 'From $45 to $89',
        },
        {
          title: 'Cost per hire',
          value: '$78',
          subTitle: '',
          subValue: 'From $45 to $89',
        },
      ],
    },
  ],
};

const EmployeesPage = () => {
  return (
    <div className={style.employeesContentContainer}>
      <div className={style.contentBlock}>
        <DashboardBlock name={'employees'} type={'large'} />
        {/* <div>1</div>
        <div>2</div>
        <div>3</div>
        <div>4</div> */}
      </div>
      <div className={style.contentBlock}>
        <EmployeeTable />
      </div>
    </div>
  );
};

export default EmployeesPage;
