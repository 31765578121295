import React, { useEffect, useState } from 'react';

import { getMainDashboard, getRecruitmentDashboard } from '../../api';
import Spinner from './Spinner';

import graphSmall from '../../assets/graph-small.png';
import largeDashboard from '../../assets/employee-dashboard.svg';

import * as style from '../style/Layout.module.scss';
import * as style from './DashboardBlock.module.scss';

const initialSmallCardData = [
  {
    title: 'Jobs Open',
    value: 58,
    subTitle: 'Active hiring',
    subValue: 4,
  },
  {
    title: 'Onboarding',
    value: 84,
    subTitle: 'Departments',
    subValue: 5,
  },
  {
    title: 'Funnel Conversion',
    value: 38,
    subTitle: 'Days Per Stage',
    subValue: 7,
  },
];

const initialLargeCardData = [
  {
    title: 'Total Employees',
    value: 218,
    subTitle: 'Largest department',
    subValue: 'Sales',
    badge: '+2% Past month',
  },
  {
    title: 'Interviews',
    value: 976,
    subTitle: 'Top position',
    subValue: 'Senior ML Engineer',
    badge: '+5% Past month',
  },
];

const SmallDashboardCard = ({ data }) => {
  // console.log(data);
  return (
    <div className={style.dashboardCard}>
      <div className={style.cardTitle}>{data.title}</div>
      <div className={style.cardValue}>{data.value}</div>
      <div className={style.cardSubValue}>
        {data.subValue} {data.subTitle}
      </div>
    </div>
  );
};

const LargeDashboardCard = ({ data }) => {
  return (
    <div className={style.dashboardLargeCard}>
      <div className={style.cardTitle}>{data.title}</div>
      <div className={style.cardValueContainer}>
        <div className={style.cardValue}>{data.value}</div>
        <div className={style.cardValueGraph}>
          <img src={graphSmall} />
        </div>
      </div>
      <div className={style.cardValueContainer}>
        <div>
          <div className={style.cardSubTitle}>{data.subTitle}</div>
          <div className={style.cardSubValue}>{data.subValue}</div>
        </div>
        <div className={style.cardValueGraphBadge}>{data.badge}</div>
      </div>
    </div>
  );
};

const DashboardBlock = ({ name, type }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (name === 'mainDashboard') {
      setLoading(true);
      getMainDashboard().then((res) => {
        setData(res.data.data);
        setLoading(false);
        // console.log(res);
      });
    }
    if (name === 'recruitmentDashboard') {
      setLoading(true);
      getRecruitmentDashboard().then((res) => {
        setData(res.data.data);
        setLoading(false);
        // console.log(res);
      });
    }
    if (name === 'employees') {
      setLoading(true);
      getRecruitmentDashboard().then((res) => {
        setData(res.data.data);
        setLoading(false);
        // console.log(res);
      });
    }
    // console.log('reloaded');
  }, []);

  const handleCalendarClose = () => {
    setReload(!reload);
  };

  return !loading ? (
    data ? (
      type == 'large' ? (
        <div className={style.dashboardBlock}>
          <div className={style.titleBar}>
            <div className={style.mainTitle}>{data.title}</div>
            <div className={style.dateSelector}>{/* <img src={datePicker} /> */}</div>
          </div>
          <div className={style.dashboardCardsContainer}>
            <img src={largeDashboard} />
          </div>
        </div>
      ) : (
        <div className={style.dashboardBlock}>
          <div className={style.titleBar}>
            <div className={style.mainTitle}>{data.title}</div>
            <div className={style.dateSelector}></div>
          </div>
          <div className={style.dashboardCardsContainer}>
            <div className={style.smallCardsContainer}>
              {data.smallCardData.map((item) => (
                <SmallDashboardCard data={item} />
              ))}
            </div>
            <div className={style.largeCardsContainer}>
              {data.largeCardData.map((item) => (
                <LargeDashboardCard data={item} />
              ))}
            </div>
          </div>
        </div>
      )
    ) : (
      <Spinner type={'loading'} />
    )
  ) : (
    <Spinner type={'loading'} />
  );
};

export default DashboardBlock;
