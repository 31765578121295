.settingsPageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 25px;
    // background-color: #ff3838;

    .contentBlock {
        margin: 25px 35px 5px 35px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // background-color: bisque;

        .mainContainer {
            width: 550px;
            // height: 300px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            // background-color: #5A5A5D;
  
            .title {
              font-size: 38px;
              font-weight: 400;
            }

            .createBtn {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 4px;
                background-color: #FF5151;
                color: white;
                padding: 10px 20px;
                margin-top: -20px;
                margin-bottom: 30px;
              }
  
              .createBtn:hover {
                cursor: pointer;
                background-color: #ff3838;
              }
  
            .formContent {
              margin: 10px 0;
              padding: 30px 0;
              width: 90%;
            //   border-radius: 10px;
              //   padding: 50px 40px;
            //   background-color: rgb(247, 247, 247);

  
              .inputBlock {
                display: flex;
                flex-direction: column;
                margin: 30px 15px 80px 15px;
                // background-color: aquamarine;
                // position: relative;
                
                label {
                  font-size: 18px;
                  font-weight: 300;
                  color: #000;
                  padding-bottom: 8px;
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                  width: 100%;
                }

                .inputArea {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: space-between;


                  input {
                    width: 100%;
                    // background-color: blueviolet;
                    box-sizing: border-box;
                    padding: 12px 16px;
                    border-radius: 5px;
                    border: 1px solid #BEBEBF;
                    color: #5A5A5D;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                  }

                  .addBtn {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100px;
                    height: 40px;
                    margin-left: 15px;
                    border-radius: 5px;
                    color: #25262A;
                    border: 1px solid #BEBEBF;
                    font-size: 14px;
                  }

                  .addBtn:hover {
                    cursor: pointer;
                  }
                }

                .listArea {
                  // background-color: #ff3838;
                  .listContainer {
                    margin: 25px 10px 0 10px;
                    padding: 0;
                    font-size: 14px;
                    color: #25262A;

                    .listItemBlock {
                      display: flex;
                      flex-direction: row;
                      // align-items: center;
                      justify-content: space-between;
                      margin-top: 2px;
                      padding: 10px 15px;
                      // background-color: #FF5151;
                      border-radius: 5px;

                      .listItem {
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      // background-color: #ff3838;

                        i {
                          margin: -1px 20px 0 0;
                        }
                      }  
                      
                      .itemEdit {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        min-width: 20px;
                        margin-left: 5px;
                        // background-color: #FF5151;
                      }
                    }
                    

                    .listItemBlock:hover {
                      background-color: #E8F0FB;
                      cursor: pointer;
                    }

                    .listItemBlockFocused {
                      display: flex;
                      flex-direction: column;
                      // align-items: center;
                      // background-color: aquamarine;
                      border: 1px solid #E8F0FB;
                      margin: 10px 0;
                      border-radius: 5px;
                      padding: 10px 15px;

                      .textEditArea {
                        margin: 20px 20px;
                        width: 90%;
                        // background-color: blueviolet;
                        box-sizing: border-box;
                        padding: 12px 16px;
                        border-radius: 5px;
                        border: 1px solid #BEBEBF;
                        color: #5A5A5D;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        resize: none;
                      }

                      .buttonsContainer {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        margin: 0 20px 15px 20px;
                        font-size: 12px;

                        .right {
                          display: flex;
                          flex-direction: row;
                          align-items: center;
                          justify-content: space-between;
                        }

                        .saveBtn {
                          display: flex;
                          flex-direction: row;
                          align-items: center;
                          justify-content: center;
                          margin-left: 20px;
                          padding: 5px 10px;
                          // background-color: #ff3838;
                          border-radius: 5px;
                          border: 1px solid black;
                          cursor: pointer;
                        }

                        .paraphraseBtn {
                          display: flex;
                          flex-direction: row;
                          align-items: center;
                          justify-content: center;
                          // margin: 0 20px;
                          padding: 5px 10px;
                          background-color: #E8F0FB;
                          border-radius: 5px;
                          cursor: pointer;
                          
                        }

                        .removeBtn {
                          // color: red;
                          text-decoration: underline;
                          cursor: pointer;
                        }

                        .saveBtn:hover { 
                          background-color: #25262A;
                          color: white;
                        }
                        .removeBtn:hover {
                          color: red;
                        }
                      }
                    }
                  }
                }
  
              

                textarea {
                  width: 100%;
                  // background-color: blueviolet;
                  box-sizing: border-box;
                  padding: 12px 16px;
                  border-radius: 5px;
                  border: 1px solid #BEBEBF;
                  color: #5A5A5D;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  resize: vertical;
                }

                .buttonsArea {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: space-between;
                  margin: 30px 0 0 0;
                  // background-color: #ff3838;

                  .underlineBtn {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    width: 130px;
                    height: 45px;
                    // text-decoration: underline;
                    color: #25262A;
                    border-radius: 5px;
                    border: 1px solid #25262A;

                    i {
                      margin-right: 5px;
                    }

                    .icon {
                      margin-right: 5px;
                    }
                  }

                  .underlineBtn:hover {
                    cursor: pointer;
                    // text-decoration: underline;
                    border: 1px solid #25262A;
                    border-radius: 5px;
                  }

                  .mainBtn {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 120px;
                    height: 45px;
                    border-radius: 5px;
                    color: #25262A;
                    text-decoration: underline;
                    // border: 1px solid #25262A;
                  }

                  .mainBtn:hover {
                    cursor: pointer;
                  }


                }

        
  
              }
            }
        }
    }
}