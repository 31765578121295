import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Spinner from './components/Spinner';
import { getJobById } from '../api/index';
import { useAtom } from 'jotai';
import { formsStructureAtom } from '../store/jobAtoms';

import * as style from './style/JobPage.module.scss';

const JobPage = () => {
  const [loading, setLoading] = useState(false);
  const [job, setJob] = useState(null);
  // const [jobStructure, setJobStructure] = useAtom(formsStructureAtom);

  const { jobId } = useParams();

  useEffect(() => {
    setLoading(true);
    getJobById({ job_id: jobId }).then((res) => {
      // console.log(data);
      if (res.ok) {
        setLoading(false);
        setJob(res.data.jobPost);
        // console.log(res);
      }
    });
  }, []);

  return loading ? (
    <Spinner type={'loading'} />
  ) : (
    <div className={style.jobContentContainer}>
      <div className={style.contentBlock}>
        <div className={style.jobPreviewContainer}>
          {job ? (
            <div className={style.mainContainer}>
              <div className={style.title}>Job Post</div>
              <div className={style.formContent}>
                {/* тут помесь атома и api */}
                {job.data.map((item) => (
                  <div className={style.paragraph}>
                    <div className={style.paragraphTitle}>{item.title}</div>
                    {typeof item.text === 'string' ? (
                      <div className={style.text}>{item.text}</div>
                    ) : (
                      <ul>
                        {item.text.map((subItem) => (
                          <li>{subItem}</li>
                        ))}
                      </ul>
                    )}
                  </div>
                ))}
              </div>
              {/* <div className={style.createBtn} onClick={() => handleCreateJob()}>
                Save
              </div> */}
              {/* <div className={style.btnArea}>
                <div className={style.btnEdit}>Edit</div>
                <div className={style.btnCopy}>Copy</div>
              </div> */}
            </div>
          ) : (
            <>No structure</>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobPage;
