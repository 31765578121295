.recruitmentContentContainer {
    // background-color: blueviolet;
    display: flex;
    flex-direction: row;
    padding-bottom: 25px;
    justify-content: space-between;

    .contentBlock {
        display: flex;
        flex-direction: column;
        margin: 25px 25px 5px 35px;
        // background-color: aqua;

    }

    .recentJobsBlock {

    }
}