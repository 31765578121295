import React, { useState, useEffect } from 'react';
import { getUserProfileData } from '../api';

import * as style from './style/SettingsPage.module.scss';

const TextInputBlock = ({ data }) => {
  // console.log('');
  return (
    <div className={style.inputBlock}>
      <label htmlFor={data.name}>{data.subTitle}</label>
      <div className={style.inputArea}>
        <input type={data.type} name={data.name} value={data.value} />
      </div>
    </div>
  );
};

// const data = [
//   {
//     subTitle: 'First Name',
//     name: 'firtsName',
//     value: 'Anah',
//     type: 'text',
//   },
//   {
//     subTitle: 'Last Name',
//     name: 'lastName',
//     value: 'Fouer',
//     type: 'text',
//   },
//   {
//     subTitle: 'Email',
//     name: 'email',
//     value: 'bossssssss@fff.com',
//     type: 'email',
//   },
//   {
//     subTitle: 'Password',
//     name: 'password',
//     value: '21312312321',
//     type: 'password',
//   },
// ];

const SettingsPage = () => {
  const [data, setData] = useState(null);
  useEffect(() => {
    getUserProfileData().then((res) => {
      if (res.ok) {
        setData(res.data);
        console.log(res);
      }
    });
  }, []);

  return data ? (
    <div className={style.settingsPageContainer}>
      <div className={style.contentBlock}>
        <div className={style.mainContainer}>
          <div className={style.title}>Settings</div>
          <div className={style.formContent}>
            {data.map((item) => (
              <TextInputBlock data={item} />
            ))}
          </div>
          <div className={style.createBtn} onClick={() => handleCreateJob()}>
            Save
          </div>
        </div>
      </div>
    </div>
  ) : (
    <>Loading...</>
  );
};

export default SettingsPage;
