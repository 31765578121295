.funnelBlock {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    width: 635px;
    // background-color: bisque;

    .titleBar {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        // background-color: #686868;

        .mainTitle {
            // background-color: aqua;
            display: flex;
            font-size: 24;
            font-weight: 500;
        }

        .dateSelector {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .funnelTableContainer {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        border: 1px solid #E0E0E0;
        border-radius: 10px;
        // background-color: blue;

        .header {
            display: flex;
            flex-direction: row;
            align-items: center;
            border-bottom: 1px solid #E0E0E0;
            margin: 20px 30px 10px 30px;
            justify-content: space-between;
            font-weight: 600;
            padding-bottom: 10px;


        }

        .body {
            display: flex;
            flex-direction: column;
            margin: 0 30px 20px 30px;
            font-size: 14px;

            .row {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 5px 0;
            }

        }

        .col1 {
            min-width: 220px;
            // background-color: aqua;
        }

        .col2 {
            min-width: 110px;
            text-align: center;
            // background-color: rgb(21, 172, 172);
        }

        .col3 {
            min-width: 110px;
            text-align: center;
            // background-color: rgb(11, 63, 63);
        }

        .col4 {
            min-width: 110px;
            text-align: center;
            // background-color: rgb(61, 77, 77);
        }

    }
}