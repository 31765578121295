export const checkAuth = async () => {
  const ffetch = new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({ status: 'ok', data: 'some data' });
    }, 1000);
  });

  const res = await ffetch;
  return res;
};

// const isEnvDev = (process.env.NODE_ENV !== 'production' && typeof window !== 'undefined' && true) || false;

const BASE_API_URL = 'https://api.londax.ai';
// const BASE_API_URL = 'http://54.235.175.92:5001';

const apiFetch = async (route, bodyData) => {
  const authToken = localStorage.getItem('authToken') ? localStorage.getItem('authToken') : null;
  // console.log('authToken', authToken);

  const body = {
    authToken: authToken ? authToken : null,
  };

  if (bodyData) {
    Object.assign(body, bodyData);
  }

  const res = await fetch(BASE_API_URL + route, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const data = {
    ok: res.ok,
    status: res.status,
    data: await res.json(),
  };

  // console.log('++++ data ', data);

  return data;
};

export const authLoginUser = async (bodyData) => {
  const route = '/login';
  const resData = await apiFetch(route, bodyData);

  return resData;

  //   if (bodyData.email == 'demo@demo.com' && bodyData.password == 'demo1234') {
  //     return { ok: true };
  //   } else {
  //     return { ok: false };
  //   }
};

export const authRegisterUser = async (bodyData) => {
  const route = '/register';
  const resData = await apiFetch(route, bodyData);
  return resData;
};

// ----------

export const getMainDashboard = async (bodyData) => {
  const route = '/main/dashboard';
  const resData = await apiFetch(route, bodyData);
  return resData;
};

export const getRecruitmentDashboard = async (bodyData) => {
  const route = '/recruitment/dashboard';
  const resData = await apiFetch(route, bodyData);
  return resData;
};

export const getFunnelData = async (bodyData) => {
  const route = '/recruitment/funnel';
  const resData = await apiFetch(route, bodyData);
  return resData;
};
// ----------

export const getJobById = async (bodyData) => {
  // var retrievedJobPost = localStorage.getItem('jobPost');

  // const resData = {
  //   type: 'preview',
  //   ok: true,
  //   jobData: JSON.parse(retrievedJobPost),
  // };

  const route = '/job-post-get';
  const resData = await apiFetch(route, bodyData);

  return resData;
};

export const getJobPostList = async (bodyData) => {
  const route = '/job-posts-list';
  const resData = await apiFetch(route, bodyData);
  return resData;
};

export const createJobPost = async (bodyData) => {
  const route = '/job-post-create';
  const resData = await apiFetch(route, bodyData);
  return resData;
};

// export const jobGenerate = async (bodyData) => {
//   const route = '/job-generate';
//   const resData1 = await apiFetch(route, bodyData);

//   console.log('----->>>', resData1);

//   const resData = {
//     jobId: 'X9yP4aRvKqW8uZ2gJ3sN6hL1tB7iA0oE5rF2mC9xG3',
//     ok: true,
//   };
//   return resData;
// };

export const jobBlockGenerate = async (bodyData) => {
  const route = '/job-block-generate';
  const res = await apiFetch(route, bodyData);

  // console.log('----->>>', res);

  const resData = {
    ok: true,
    data: [res.data.data],
  };
  return resData;
};

export const jobListBlockGenerate = async (bodyData) => {
  const route = '/job-list-block-generate';
  const res = await apiFetch(route, bodyData);

  // console.log('----->>>', res);

  const resData = {
    ok: true,
    data: res.data.data,
  };
  return resData;
};

export const jobBlockParaphrase = async (bodyData) => {
  const route = '/job-block-paraphrase';
  const res = await apiFetch(route, bodyData);

  // console.log('----->>>', res);

  const resData = {
    ok: true,
    data: res.data.data,
  };
  return resData;
};

export const listItemParaphrase = async (bodyData) => {
  const route = '/list-item-paraphrase';
  const res = await apiFetch(route, bodyData);

  // console.log('----->>>', res);

  const resData = {
    ok: true,
    data: res.data.data,
  };
  return resData;
};

export const getUserProfileData = async (bodyData) => {
  const route = '/main/user-profile-data';
  const res = await apiFetch(route, bodyData);

  // console.log('----->>>', res);

  const resData = {
    ok: true,
    data: res.data.data,
  };
  return resData;
};
